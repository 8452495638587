const QUERY_KEYS = {
  GEM: 'GEM',
  GEM_ON_SALE: 'GEM_ON_SALE',
  SALES_POPULATED: 'SALES_POPULATED',
  GEMS_FOR_OWNER: 'GEMS_FOR_OWNER',
  GEMS_FOR_CREATOR: 'GEMS_FOR_CREATOR',
  GET_PROFILE: 'GET_PROFILE',
  GET_SUPPLY_FOR_CREATOR: 'GET_SUPPLY_FOR_CREATOR',
  GET_IMAGE_DATA: 'GET_IMAGE_DATA',
  IS_FREE_MINT_AVAILABLE: 'IS_FREE_MINT_AVAILABLE',
  BLACKLIST: 'BLACKLIST',
};

export default QUERY_KEYS;
